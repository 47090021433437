<template>
  <div class="login-container">
    <input class="hide" type="email">
    <input class="hide" type="password">
    <mdb-row class="login-frame">
      <mdb-col col="6" class="login-left">
        <div class="left-container">
          <div>
            <img src="@/assets/images/img_theme.png" alt="">
            <h2>지금바로 회원가입 후<br/>
              다양한 리소스를 받아보세요!</h2>
          </div>
        </div>
      </mdb-col>
      <mdb-col col="6" class="login-right">
        <div class="right-container">
          <div class="login-box">
            <h1>로그인</h1>
            <div class="input-box">
              <input type="text" placeholder="이메일 아이디 입력" class="email-id custom-input-box"
                     :class="{warning:!isValidate}" v-model="email">
              <p v-if="!isValidate" class="warning-text">로그인 정보를 확인해주세요.</p>
              <input type="password" placeholder="비밀번호 입력" class="password custom-input-box"
                     v-model="password" v-on:keypress.enter.prevent="getLogin" autoComplete="off">
              <button class="login-btn custom-btn" @click="getLogin">로그인</button>
              <div class="check-box">
                <!--                <input class="form-check-input" type="checkbox" id="checkbox" v-model="isLogin">-->
                <!--                <label for="checkbox">로그인 상태 유지</label>-->
                <p>아이디/비밀번호를 잊으셨나요?</p>
              </div>
            </div>
            <button class="signUp-btn custom-btn" @click="signUp">라마켓이 처음이신가요?</button>
          </div>
        </div>
      </mdb-col>
    </mdb-row>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {isValidEmail, isValidPassword} from "@/lib/validation";
import {auth, Timestamp, firestore} from "@/firebase/firebaseConfig";
import {mdbRow, mdbCol} from "mdbvue";
import Progress from "@/components/Progress";

export default {
  name: "Login",
  components: {
    mdbRow, mdbCol,
    Progress
  },
  data() {
    return {
      fbCollection: 'user',
      email: '',
      password: '',
      lastLog: '',
      isProgress: false,
      isValidate: true,
      isLogin: true,
    }
  },
  methods: {
    validateInput() {
      if (this.email.trim().length === 0) {
        this.isValidate = false;
        return false
      }
      if (this.password.trim().length === 0) {
        this.isValidate = false;
        return false
      }
      if (!isValidEmail(this.email)) {
        this.isValidate = false;
        return false
      }
      if (!isValidPassword(this.password)) {
        this.isValidate = false;
        return false
      }
      return true;
    },
    async checkDouble() {
      const self = this;
      const querySnapshot = await firestore.collection('user')
          .where('auth.email', '==', self.email)
          .get();
      if (querySnapshot.size !== 0) {
        return true;
      } else {
        // self.$swal.fire({title: '등록된 아이디 없음', text: '등록된 아이디가 없습니다.', icon: 'error'})
        return false;
      }
    },
    async getLogin() {
      const self = this;

      if (!self.validateInput()) return

      if (!(await this.checkDouble())) return;

      this.isProgress = true;

      try {
        const result = await auth.signInWithEmailAndPassword(this.email, this.password);
        self.isProgress = false;
        await this.setLog(result.user.uid);
      } catch (e) {
        self.isProgress = false;
        self.$swal.fire({
          title: 'Login failed',
          text: `Error: ${e.message}`, // Show the specific error message
          icon: 'error'
        });
      }
    },
    async setLog(uid) {
      const self = this;
      const defRef = firestore.collection(self.fbCollection);

      const doc = await defRef.doc(uid).get();
      self.lastLog = doc.data().currentLog;
      await defRef.doc(uid).update({
        lastLog: self.lastLog,
        currentLog: Timestamp.now()
      });
      self.isProgress = false;
      localStorage.setItem('userRole','user');
      await self.$router.replace('/');
    },
    signUp() {
      this.$router.push({name: 'SignUp'})
    }
  },

}
</script>

<style scoped>

.login-container {
  font-weight: 500;
  background: #FFFFFF; /* 배경색 변경 */
  height: 583px;
  width: 100%;
}

.hide {
  position: absolute;
  z-index: -10;
}

.login-frame {
  margin: 0 auto;
  background: #ffffff;
  width: 100%;
  height: 100%;
}

.login-frame .login-left,
.login-frame .login-right {
  margin: 0;
  padding: 0;
}

.login-frame .login-left {
  background: #FBF4EB;
  display: flex;
  justify-content: flex-end;
}

.login-left .left-container,
.login-right .right-container {
  width: 612px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-left .left-container img {
  width: 482px;
  height: 350px;
}

.login-left h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.login-right .right-container {
}

.right-container .login-box h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 60px;
}

.right-container .login-box .input-box {
  width: 343px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.login-box .input-box .email-id::placeholder,
.login-box .input-box .password::placeholder {
  color: #C7C6CD;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}

.login-box .input-box .email-id:focus,
.login-box .input-box .password:focus {
  outline: 1px solid #FF8A00;
}

.login-box .input-box .warning-text {
  color: #EF4444;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.26px;
  margin: 6px 16px 0;
  text-align: start;
}

.login-box .input-box .warning {
  border: 1px solid #EF4444;
}


.right-container .login-box .input-box .password {
  margin: 10px 0;
}

.right-container .login-box .input-box .login-btn {
  background: #1C2027;
}

.right-container .login-box .input-box .check-box {
  display: flex;
  margin-top: 20px;
}

.right-container .login-box .input-box .check-box .form-check-input {
  width: 18px;
  height: 18px;
}

.form-check-input[type="checkbox"] + label {
  margin: 0;
  padding-left: 24px;
}

.form-check-input[type="checkbox"] + label:before {
  margin: 2px 0 0 0 !important;
  border: 2px solid #E4E4E7;
}

.form-check-input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #ff8a00;
  border-bottom: 2px solid #ff8a00;
  border-top: 0;
  border-left: 0;
}

.right-container .login-box .input-box .check-box label {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  margin: 0;
}

.right-container .login-box .input-box .check-box p {
  color: #A1A1AA;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  margin: 0 0 0 auto;
  cursor: pointer;
}


.right-container .signUp-btn {
  width: 100%;
  background: #FBF4EB;
  color: #B6A691;
}

</style>